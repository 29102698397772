import Cookies from 'js-cookie'

const TokenKey = 'xdk_local_token'

export function getToken() {
    return Cookies.get(TokenKey)
}
export function setUserInfo(userInfo) {
    // 七天过期
    __saveUserInfo(userInfo)
    __setToken(userInfo.token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getUserInfo() {
    const userName = localStorage.getItem('userName')
    const userId = localStorage.getItem('userId')
    const roleId = localStorage.getItem('roleId')
    const avatar = localStorage.getItem('avatar')
    const sysMenu = JSON.parse(localStorage.getItem('sysMenu'))

    return { userName, userId, roleId, avatar, sysMenu }
}

function __saveUserInfo(userInfo) {
    localStorage.setItem('avatar', userInfo.avatar || 'http://oss.xingdongdao.com/wechat/1590478216713-rimlu4fi.png')
    localStorage.setItem('userName', userInfo.userName)
    localStorage.setItem('userId', userInfo.userId)
    localStorage.setItem('roleId', userInfo.roleId)
    localStorage.setItem('timestamp', new Date().getTime())
    localStorage.setItem('sysMenu', JSON.stringify(userInfo.sysMenu))
}

function __setToken(token) {
    return Cookies.set(TokenKey, token, { expires: 7 })
}
