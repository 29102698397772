import Vue from 'vue'
import Composition from '@vue/composition-api'
import monment from 'vue-moment'
import ElementUI from 'element-ui'
import core from './core'
import store from './store'
import router from './router'

import { trail } from './business/trail'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import '@/icons' // icon
import '@/permission' // permission control

Vue.use(ElementUI)
Vue.use(Composition)
Vue.use(monment)
Vue.config.productionTip = false
Vue.prototype.$trail = trail

let instance = null

function render() {
    instance = new Vue({
        el: '#app',
        router,
        store,
        render: h => h(core)
    })
}

if (!window.__POWERED_BY_QIANKUN__) {
    render()
}

export async function bootstrap() {
    // eslint-disable-next-line no-console
    console.log('vue app bootstraped')
}

export async function mount(props) {
    // eslint-disable-next-line no-console
    console.log('props from main framework', props)
    render()
}

export async function unmount() {
    instance.$destroy()
    instance = null
}
