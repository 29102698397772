
// 路由权限
export function needSideBarShow(routes, sysMenu) {
    const res = new Map()
    const res2 = []
    sysMenu.map((sysItem, index) => {
        routes.map(route => {
            if ('/' + sysItem.index === route.path) {
                res.set(index, route)
            }
        })
    })
    for (const [key, value] of res) {
        const newValueChildren = []
        // 当前匹配的权限路由
        sysMenu[key].children.map(sysMenuChildrenItem => {
            // 当前需要更改的路由
            value.children.map(valueChildrenItem => {
                if (sysMenuChildrenItem.index === valueChildrenItem.path) {
                    newValueChildren.push(valueChildrenItem)
                }
            })
        })
        // index 特殊处理一下
        value.children.map(valueChildrenItem => {
            if (valueChildrenItem.path === 'index') {
                newValueChildren.push(valueChildrenItem)
            }
        })
        value.children = newValueChildren
        res2.push(value)
    }
    return res2
}

