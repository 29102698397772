import { login } from '@/api/login'
import { getToken, setUserInfo, removeToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        avatar: '',
        userId: 0,
        roleId: '[]',
        sysMenu: []
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_USERID: (state, userId) => {
        state.userId = userId
    },
    SET_ROLEID: (state, roleId) => {
        state.roleId = roleId
    },
    SET_MENU: (state, sysMenu) => {
        state.sysMenu = sysMenu
    }
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            login({
                account: userInfo.account,
                password: userInfo.password
            }).then(res => {
                resolve(res)
                commit('SET_TOKEN', res.data.token)
                commit('SET_AVATAR', 'http://oss.xingdongdao.com/wechat/1590478216713-rimlu4fi.png')
                commit('SET_NAME', res.data.userName)
                commit('SET_USERID', res.data.userId)
                commit('SET_ROLEID', res.data.roleId)
                commit('SET_MENU', res.data.sysMenu)
                setUserInfo(res.data)
            }).catch(error => {
                console.log(error)
                reject(error)
            })
        })
    },

    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            const token = getToken()
            const userInfo = {
                token,
                ...getUserInfo()
            }
            commit('SET_TOKEN', userInfo.token)
            commit('SET_AVATAR', userInfo.avatar)
            commit('SET_NAME', userInfo.userName)
            commit('SET_USERID', userInfo.userId)
            commit('SET_ROLEID', userInfo.roleId)
            commit('SET_MENU', userInfo.sysMenu)
            resolve(userInfo)
        })
    },

    // user logout
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            removeToken() // must remove  token  first
            resetRouter()
            commit('RESET_STATE')
            resolve()
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

