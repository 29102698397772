<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger"
      @toggleClick="toggleSideBar"
    />
    <breadcrumb class="breadcrumb" />
    <div class="right-menu">
      <el-dropdown size="small" trigger="click">
        <div style="cursor: pointer; margin-right: 10px">
          <span>你好, {{ username }}</span>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";

export default {
  name: "nav-bar",
  components: {
    Breadcrumb,
    Hamburger,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar"]),
    username() {
      const username = localStorage.getItem("userName");
      return username || "管理员";
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";
@include b(navbar) {
  height: 50px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
@include b(hamburger) {
  line-height: 46px;
  height: 100%;
  float: left;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
  margin-right: 15px;
}
.breadcrumb {
  float: left;
}

@include b(right-menu) {
  float: right;
  height: 100%;
  line-height: 50px;
}
</style>
