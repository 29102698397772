import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

/**
 * TODO: kitty 的权限问题要之后再评估，目前先于cat保持同步
 * 在 kitty 本身的权限问题没有解决前，不开新的迁移工作
 */

/**
 * 目前 router 的逻辑是 :
 * 名字路径与 cat(login 接口返回值) 对应,
 * 不需要在侧边栏展示的，放到对应路径下的子路路由中，path = 'extra'
*/

// kitty 新增页面采用新的逻辑。不要在 cat 原有菜单上加页面

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
    },

    {
        path: '/',
        component: Layout,
        redirect: '/tools/searchInfo'
    },
    {
        path: '/dataStatistics',
        component: Layout,
        name: '审核/标注-数据统计',
        children: [{
            path: 'index',
            name: '审核/标注-数据统计',
            meta: { title: '审核/标注-数据统计', icon: 'sousuo' },
            component: () => import('@/views/statistics/dataStatistics')
        }]
    },
    {
        path: '/checkWorkday',
        component: Layout,
        name: '审核工作日表',
        children: [{
            path: 'index',
            name: '审核工作日表',
            meta: { title: '审核工作日表', icon: 'sousuo' },
            component: () => import('@/views/checkWorkday/checkWorkday')
        }]
    },
    {
        path: '/safeAudit',
        component: Layout,
        name: '安全审核',
        meta: { title: '安全审核', icon: 'caidan' },
        children: [
            {
                path: 'textReview',
                component: () => import('@/views/contentAudit/textReview'),
                name: '文本内容检测',
                meta: { title: '日记实时安全审核', icon: 'kabao' }
            },
            {
                path: 'checkContentSafe',
                component: () => import('@/views/contentAudit/checkContentSafe'),
                name: '视频图片实时检测',
                meta: { title: '图片视频审核V2', icon: 'kabao' }
            },
            {
                path: 'hotNoteReview',
                component: () => import('@/views/contentAudit/hotNoteReview'),
                name: '高流量日记人工复审',
                meta: { title: '高流量日记审核', icon: 'huiyuan' }
            },
            {
                path: 'usersComplain',
                component: () => import('@/views/contentAudit/usersComplain'),
                name: '用户投诉审核',
                meta: { title: '用户投诉审核', icon: 'xiaoxi' }
            },
            {
                path: 'forbidHabit',
                component: () => import('@/views/safeAudit/forbidHabit'),
                name: '封禁圈子',
                meta: { title: '封禁圈子', icon: 'kabao' }
            },
            {
                path: 'forbidUserSearch',
                component: () => import('@/views/safeAudit/forbidUserSearch'),
                name: '禁言用户查询',
                meta: { title: '所有处罚记录', icon: 'kabao' }
            },
            {
                path: 'violationHistory',
                component: () => import('@/views/safeAudit/violationHistory'),
                name: '用户违规历史查询',
                meta: { title: '用户违规历史查询', icon: 'kabao' }
            }
        ]
    },
    {
        path: '/tools',
        component: Layout,
        name: '查询工具',
        meta: { title: '查询工具', icon: 'caidan' },
        children: [
            {
                path: 'userNoteReview',
                component: () => import('@/views/contentAudit/userNoteReview'),
                name: '用户日记审核',
                meta: { title: '用户日记查询', icon: 'kabao' }
            },
            {
                path: 'searchInfo',
                name: '用户和圈子信息查询1',
                meta: { title: '用户和圈子信息查询', icon: 'sousuo' },
                component: () => import('@/views/searchInfo/index')
            }
        ]
    },
    {
        path: '/sensitiveWords',
        component: Layout,
        name: '敏感词高亮管理',
        meta: { title: '敏感词高亮管理', icon: 'caidan' },
        children: [
            {
                path: '/sensitiveWords',
                component: () => import('@/views/sensitiveWords/sensitiveWords'),
                name: '敏感词高亮管理',
                meta: { title: '敏感词高亮管理', icon: 'caidan' }
            }
        ]
    },
    { path: '*', redirect: '/login', hidden: true }
]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
