import { getSensitiveWordsList } from '@/api/sensitiveAudit/sensitiveAudit'

const state = {
    sensitiveWords: []
}

const mutations = {
    changeSensitiveWords: (state, data) => {
        state.sensitiveWords = data
    }
}

const actions = {
    getSensitiveWords({ commit }) {
        return new Promise((resolve, reject) => {
            getSensitiveWordsList({})
                .then(res => {
                    commit('changeSensitiveWords', res.data)
                    resolve(res)
                })
                .catch(err => {
                    console.error(err)
                    reject(err)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

