import { xGet, xPost } from '../index'

export const uploadSensitiveExcel = data => {
    const formData = new FormData()
    formData.append('excelFile', data.file)
    return xPost({
        url: 'https://earth-callback.xingdongdao.com/api/highlight/keyword/upload?fileName=' + data.file.name,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const getSensitiveWordsList = data => xGet({ url: '/api/highlight/keyword/get', data })
