import { xPost } from '@/api/index'

function initOperator() {
    const config = {
        operator: null
    }
    const setOperator = () => {
        if (config.operator && config.operator.length > 0) return config
        else {
            const operator = localStorage.getItem('userName')
            if (operator) config.operator = operator
            else {
                alert('你的登录状态已过期，请重新登录避免丢失审核记录')
                return { operator: null }
            }
            return config
        }
    }

    return setOperator()
}

/**
 * @description 在调用了接口之后添加审核记录
 * @param {Function} fn 要调用的方法
 * @param {Object} Params 请求体
 * @param auditBusinessValue 审核业务包含标识唯一审核记录的id
 * @param auditType 审核的业务类型，1：日记 2：评论 3：文本 4：文本审核 5：投诉审核
 * @param commentAction 对评论产生的审核操作；1:删除，2:屏蔽，3:通过
 * @param complainAction	对投诉的审核操作 1:投诉有效，2:投诉无效
 * @param fileAction 对音视频的审核操作；1:删除附件，2:误报
 * @param noteAction 对日记产生的审核操作；1:删除，2:继续曝光，3:取消曝光，4:通过，5:通过并精选，6:不达标，7:不相符
 * @param operator 操作人
 * @param page 枚举: infoDetection,commandMarket,topicCheck,contentCheck,complain
 * @param textAction 对文本的审核操作 1:敏感，2：非敏感
 * @param userAction	1:禁言，2:关小黑屋
 */
export const trail = (fn, params) => (...args) => {
    let trailParams = null
    if (Array.isArray(params)) {
        trailParams = params.map(param => Object.assign({}, initOperator(), param))
    } else trailParams = Object.assign({}, initOperator(), params)
    return Promise.resolve(fn(...args)).then(res => {
        if (Array.isArray(trailParams)) {
            xPost({
                url: '/api/v1/operation/batch/create',
                data: trailParams
            })
        } else {
            xPost({
                url: '/api/v1/operation/create',
                data: trailParams
            })
        }
        return res
    })
}

export const getOperator = () => initOperator().operator

